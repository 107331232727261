define(['app', '$window'], (app, $window) => {

  const trustPilotWidget = () => {
    const component = {};

    const _config = {
      iframeMouseOver: false
    };

    const _init = (element) => {
      component.element = element;

      component.bind();

      return component;
    };

    const _bind = () => {
      const trustPilotIframe = component.element.querySelector('iframe');

      if (!trustPilotIframe) return;

      // These three event listeners have the combined effect of being able to detect when a user
      // has clicked inside of the Trust Pilot iframe
      // A simple 'click' event listener doesn't work in this case

      trustPilotIframe.addEventListener('mouseover', () =>
        component.config.iframeMouseOver = true
      );

      trustPilotIframe.addEventListener('mouseout', () =>
        component.config.iframeMouseOver = false
      );

      $window.addEventListener('blur', () => {
        if (component.config.iframeMouseOver) {
          app.publish('columbo/track', '_tracking_footer_credit_trustpilot', 'click', 'footer trustpilot widget');
        }
      });
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;

    return component;
  };

  return trustPilotWidget;
});
